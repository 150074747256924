import Head from 'next/head';
import { LoginComponent } from '#/common/components/Login';

export function LoginPage() {
  return (
    <div className="flex h-auto flex-col px-7 sm:mx-auto sm:mt-16 sm:w-96 sm:border sm:border-black-4">
      <Head>
        <title>Entre | Entremalhas</title>
        <meta
          name="description"
          content="Acesse sua conta na Entremalhas."
        />
      </Head>
      <LoginComponent />
    </div>
  );
}
